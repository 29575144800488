<template>
  <div class="dynamic-form-editor">
    <h4 class="font-weight-bold">
      {{ editorTitle }}
    </h4>

    <section class="dynamic-form-editor__subjects">
      <VueDraggable
        handle=".subject-layout-drag-handler"
        :list="vModelForPages"
        :disabled="isReadOnly"
        @end="handleSortingPages"
      >
        <b-card v-for="(page, index) in vModelForPages" class="mt-3" :key=" `${vModelForPages[index]._id}_${rerenderingCount}`">
          <SubjectLayout
            :ref="'subjectLayoutRef+'+vModelForPages[index]._id"
            :index="index"
            :title.sync="vModelForPages[index].title"
            :move-up-is-disabled="index == 0"
            :move-down-is-disabled="index == vModelForPages.length - 1"
            :expanded.sync="expandedStateForSubjects[vModelForPages[index]._id]"
            :readonly="isReadOnly"
            :visible-conditions.sync="vModelForPages[index].visible_when_any"
            :field-options-for-visible-conditions="sanitizedFields"
            @top="moveToTop(index)"
            @up="moveUp(index)"
            @down="moveDown(index)"
            @bottom="moveToBottom(index)"
            @remove="removePage(index)"
          >
            <template #default>
              <!-- section page start -->
              <p>分區設定：</p>
              <div class="mt-3">
                <VueDraggable
                  handle=".option-drag-handler"
                  :list="sectionsOfPage(page)"
                  :disabled="isReadOnly"
                  @end="e => handleSortingSections(e, page)"
                >
                  <div v-for="(option, optionIndex) in sectionsOfPage(page)" class="mb-3" :key="index + '-opt-' + optionIndex">
                    <div class="d-flex align-items-center">
                      <div class="flex-grow-1 flex-shrink-1 d-flex align-items-center section-input" >
                        <i class="fa fa-bars mr-3 option-drag-handler"></i>
                        <b-form-input
                          placeholder="請輸入選項"
                          v-model="option.title"
                          :disabled="isReadOnly"
                          :ref="`section_name_${page._id}_${optionIndex}`">
                        </b-form-input>
                      </div>
                      <div class="d-flex flex-grow-1 flex-shrink-0 align-items-center" v-if="!isReadOnly">
                        <b-button class="ml-2 p-1 d-inline-flex align-items-center text-nowrap" variant="inverse-success" @click="createSection(page, optionIndex)">
                          <span class="mdi mdi-18px mdi-playlist-plus"></span>
                          增加
                        </b-button>
                        <b-button class="ml-2 p-1 d-inline-flex align-items-center text-nowrap" variant="inverse-danger" @click="removeSection(option)">
                          <span class="mdi mdi-18px mdi-playlist-remove"></span>
                          移除
                        </b-button>
                        <b-button class="ml-2 p-1 d-inline-flex align-items-center text-nowrap" :variant="deepGet(option, 'visible_when_any.length', 0) > 0 ? 'primary' : 'secondary'" @click="toggleVisibleCondition(option)" :disabled="isReadOnly" >
                          <span class="mdi mdi-lightbulb-on-outline"></span>
                          觸發顯示
                        </b-button>
                      </div>
                    </div>
                    <VisibleConditionEditor
                      class="pt-3 pl-5"
                      v-if="deepGet(option, 'visible_when_any.length', 0) > 0"
                      v-model="option.visible_when_any"
                      :fieldOptionsForVisibleConditions="sanitizedFields"
                    />
                  </div>

                  <b-button class="p-1 d-inline-flex align-items-center text-nowrap" variant="inverse-success" @click="createSection(page)">
                    <span class="mdi mdi-18px mdi-playlist-plus"></span>
                    增加分區
                  </b-button>
                </VueDraggable>
              </div>
            </template>
          </SubjectLayout>
        </b-card>
      </VueDraggable>
    </section>

    <div v-if="!isReadOnly" class="subject-type-menu mt-3">
      <span class="subject-type-menu__icon mdi mdi-24px mdi-plus"></span>
      <div class="subject-type-menu__button-wrapper">
        <b-button class="subject-type-menu__create-button" variant="inverse-primary" @click="createNewPage">
          <i class="mdi mdi-18px mdi-note-plus-outline"></i>
          分頁
        </b-button>
      </div>
    </div>

    <div v-if="!isReadOnly" class="mt-3 d-flex justify-content-center">
      <b-button variant="outline-danger" class="mr-3" @click="$emit('cancel')">
        返回
      </b-button>
      <b-button variant="success" @click="outputSubjects">
        儲存
      </b-button>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import VueDraggable from "vuedraggable";
import SubjectLayout from './SubjectLayoutForPageAndSection.vue';
import VisibleConditionEditor from "@/components/DynamicFormEditor/VisibleConditionEditor.vue";
import _ from "lodash";

export default {
  components: {
    VueDraggable,
    SubjectLayout,
    VisibleConditionEditor,
  },
  props: {
    editorTitle: {
      type: String,
      default: '動態表單編輯器',
    },
    inputSubjects: {
      type: Array,
      default: () => { return [] },
    },
    pages: {
      type: Array,
      default: () => ([]),
    },
    sections: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    isReadOnly() {
      return false;
    },
    sanitizedFields() {
      return this.inputSubjects
        .map(inputSubject => {
          // 把舊格式的 subject.config 轉換成沒有 config 的扁平格式
          const { config, ...subject } = inputSubject
          return { ...config, ...subject }
        })
    },
  },
  data() {
    return {
      vModelForPages: [],
      vModelForSections: [],
      expandedStateForSubjects: {},
      rerenderingCount: 0,
    }
  },
  watch: {
    pages() {
      this.vModelForPages = this.pages
    },
    sections() {
      this.vModelForSections = this.sections
    },
  },
  mounted() {
    this.vModelForPages = this.pages
    this.vModelForSections = this.sections
  },
  methods: {
    deepGet: _.get,
    uuidv4,
    sectionsOfPage(page) {
      return _.sortBy(this.vModelForSections.filter(section => section.page_id === page._id), 'order')
    },
    createNewPage() {
      const newPage = {
        _id: uuidv4(),
        title: `第${this.vModelForPages.length + 1}頁`,
        order: this.vModelForPages.length + 1,
        visible_when_any: [],
      }
      this.vModelForPages.push(newPage);
      this.expandedStateForSubjects[newPage._id] = true;
      this.focusPageTitleInput(newPage._id);
    },
    focusPageTitleInput(pageId) {
      this.$nextTick(() => {
        this.$refs[`subjectLayoutRef+${pageId}`][0].$refs.titleInputRef.focus();
      });
    },
    removePage(index) {
      const pageId = _.get(this.vModelForPages, `[${index}]._id`)
      this.vModelForSections = this.vModelForSections.filter(section => section.page_id !== pageId)
      this.vModelForPages.splice(index, 1);

      this.rerenderingCount++;
    },
    createSection(page, currentIndex = undefined) {
      const sections = this.sectionsOfPage(page)
      const sectionCount = sections.length
      const indexToInsert = currentIndex === undefined ? sectionCount : currentIndex

      const newSection = {
        _id: uuidv4(),
        page_id: page._id,
        order: 0,
        visible_when_any: [],
      }
      this.vModelForSections.push(newSection)

      sections.splice(indexToInsert + 1, 0, newSection)

      let newOrder = 1
      sections.forEach(section => {
        const matchedIndex = this.vModelForSections.findIndex(s => s._id === section._id)
        this.vModelForSections[matchedIndex].order = newOrder
        newOrder++
      })

      this.rerenderingCount++;

      this.$nextTick(() => {
        const indexToFocus = currentIndex === undefined ? sectionCount : currentIndex + 1
        this.$refs[`section_name_${page._id}_${indexToFocus}`][0].select();
      });
    },
    removeSection(option) {
      const sectionIndex = this.vModelForSections.findIndex(section => section._id === option._id)
      this.vModelForSections.splice(sectionIndex, 1);

      this.rerenderingCount++;
    },
    outputSubjects() {
      this.$emit('update:pages', this.vModelForPages);
      this.$emit('update:sections', this.vModelForSections);
      this.$emit('output', { pages: this.vModelForPages, sections: this.vModelForSections });
    },
    moveToTop(index) {
      if (index === 0) return;

      const subjectToMove = this.vModelForPages[index];
      for (let i = index; i > 0; i--) {
        this.vModelForPages[i] = this.vModelForPages[i - 1];
      }
      this.vModelForPages[0] = subjectToMove;
      this.rearrangePageOrders()

      this.rerenderingCount++;
    },
    moveUp(index) {
      if (index === 0) return;

      let temp = this.vModelForPages[index];
      this.vModelForPages[index] = this.vModelForPages[index - 1];
      this.vModelForPages[index - 1] = temp;
      this.rearrangePageOrders()

      this.rerenderingCount++;
    },
    moveDown(index) {
      if (index === this.vModelForPages.length - 1) return;

      let temp = this.vModelForPages[index];
      this.vModelForPages[index] = this.vModelForPages[index + 1];
      this.vModelForPages[index + 1] = temp;
      this.rearrangePageOrders()

      this.rerenderingCount++;
    },
    moveToBottom(index) {
      if (index === this.vModelForPages.length - 1) return;

      const subjectToMove = this.vModelForPages[index];
      for (let i = index; i < this.vModelForPages.length - 1; i++) {
        this.vModelForPages[i] = this.vModelForPages[i + 1];
      }
      this.vModelForPages[this.vModelForPages.length - 1] = subjectToMove;
      this.rearrangePageOrders()

      this.rerenderingCount++;
    },
    toggleVisibleCondition(option) {
      let condition = _.get(option, 'visible_when_any', [])
      if(!condition.length) {
        condition.push({
          field_id: null,
          value: null,
        })
      } else {
        condition.splice(0, 1)
      }
    },
    rearrangePageOrders() {
      this.vModelForPages.forEach((page, index) => {
        this.$set(this.vModelForPages[index], 'order', index + 1)
      })
    },
    handleSortingPages() {
      this.rearrangePageOrders()
    },
    handleSortingSections(event, page) {
      const sections = this.sectionsOfPage(page)
      const newIndex = event.newIndex
      const oldIndex = event.oldIndex

      const movingSection = sections[oldIndex]
      sections.splice(oldIndex, 1)
      sections.splice(newIndex, 0, movingSection)

      let newOrder = 1
      sections.forEach(section => {
        const matchedIndex = this.vModelForSections.findIndex(s => s._id === section._id)
        this.vModelForSections[matchedIndex].order = newOrder
        newOrder++
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.subject-type-menu {
  display: inline-flex;
  align-items: center;
  border: 1px dashed #aaa;
  border-radius: 12px;
  padding: 4px 8px;

  &__icon {
    flex: 0 0 auto;
    margin: 0 8px;
  }

  &__button-wrapper {
    flex: 1 1 auto;
    flex-wrap: wrap;

    > * {
      margin: 4px 8px;
    }
  }

  &__create-button {
    display: inline-flex;
    align-items: center;
    text-wrap: nowrap;

    i.mdi {
      margin-right: 4px;
    }
  }
}

.section-input {
  max-width: 390px;
}
</style>
