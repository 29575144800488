<template>
  <div>
    <!-- Page Header Start -->
    <div class="page-title-header">
      <div class="page-header">
        <b-breadcrumb class="m-0">
          <b-breadcrumb-item
            :to="{
              name: 'DashboardHome',
            }"
          >
            <i class="fa fa-home"></i>
          </b-breadcrumb-item>

          <b-breadcrumb-item>
            登記預約中心
          </b-breadcrumb-item>

          <b-breadcrumb-item :to="{ name: 'BookingPresetList' }">
            登記預約中心
          </b-breadcrumb-item>

          <b-breadcrumb-item active
            >{{ isEditing ? "編輯預約設定檔" : "新增預約設定檔" }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <!-- Page Header End -->

    <!-- Form Start -->
    <div class="email-wrapper wrapper">
      <div class="row bg-white">
        <div class="col-md-2 mail-sidebar pt-3">
          <div class="menu-bar">
            <ul class="menu-items">
              <li
                :class="{
                  active: step == '',
                  'has-checkbox': true,
                  'has-checkbox--checked': bookingPreset.id,
                }"
                @click="goToStep('')"
              >
                <a>基本設定</a>
              </li>
              <li
                :class="{
                  active: step == '#welcome',
                  'has-checkbox': true,
                  'has-checkbox--checked':
                    deepGet(bookingPreset, 'config.welcome_page.enable', false),
                  disabled: !bookingPreset.id,
                }"
                @click="goToStep('#welcome')"
              >
                <a>歡迎頁</a>
              </li>
              <li
                :class="{
                  active: step == '#content',
                  disabled: !bookingPreset.id,
                }"
                @click="goToStep('#content')"
              >
                <a>內容頁</a>
              </li>
              <li
                :class="{
                  active: step == '#finished',
                  disabled: !bookingPreset.id,
                }"
                @click="goToStep('#finished')"
              >
                <a>完成頁</a>
              </li>
              <li
                :class="{
                  active: step == '#fields',
                  'has-checkbox': true,
                  'has-checkbox--checked':
                    deepGet(bookingPreset, 'config.fields.length', 0) > 0,
                  disabled: !bookingPreset.id,
                }"
                @click="goToStep('#fields')"
              >
                <a>欄位設定</a>
              </li>
              <li
                :class="{
                  active: step == '#collection-info',
                  'has-checkbox': true,
                  'has-checkbox--checked':
                    deepGet(bookingPreset, 'config.fields.length', 0) > 0,
                  disabled: !bookingPreset.id,
                }"
                @click="goToStep('#collection-info')"
              >
                <a>欄位資訊</a>
              </li>
              <li
                :class="{
                  active: step == '#mapping',
                  disabled: !bookingPreset.id,
                }"
                @click="goToStep('#mapping')"
              >
                <a>基本預約設定</a>
              </li>
              <li
                :class="{
                  active: step == '#wizard-mode',
                  disabled: !bookingPreset.id,
                }"
                @click="goToStep('#wizard-mode')"
                v-if="bookingPreset.type == 'wizard'"
              >
                <a>精靈模式設定</a>
              </li>
              <li
                :class="{
                  active: step == '#liff-setting',
                  disabled: !bookingPreset.id,
                }"
                @click="goToStep('#liff-setting')"
              >
                <a>LIFF 預約紀錄</a>
              </li>
              <li
                :class="{
                  active: step == '#booking-setting',
                  disabled: !bookingPreset.id,
                }"
                @click="goToStep('#booking-setting')"
              >
                <a>推播設定</a>
              </li>
              <li
                :class="{
                  active: step == '#pages-and-sections',
                  disabled: !bookingPreset.id,
                }"
                @click="goToStep('#pages-and-sections')"
              >
                <a>分頁與分區設定</a>
              </li>
              <li
                v-if="checkPermission([consts.BOOKING_LIST_QUOTATION_SETTING])"
                :class="{
                  active: step == '#booking-list-quotation-setting',
                  disabled: !bookingPreset.id,
                }"
                @click="goToStep('#booking-list-quotation-setting')"
              >
                <a>估價單設定</a>
              </li>
              <li
                :class="{
                  active: step == '#limit-setting',
                  disabled: !bookingPreset.id,
                }"
                @click="goToStep('#limit-setting')"
              >
                <a>預約次數限制</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-10 main-view">
          <b-overlay :show="showLoading">
            <b-card>
              <!-- 基本資料 section start -->
              <section v-if="step == ''">
                <!-- 欄位部分 Start -->
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">
                    {{ isEditing ? "編輯預約設定檔" : "新增預約設定檔" }}
                  </h4>
                  <div class="col-12 col-xl-8">
                    <validation-error-alert
                      v-if="validationErrors"
                      :errors="validationErrors"
                    ></validation-error-alert>
                    <!-- 名稱 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="*預約設定檔名稱"
                    >
                      <b-form-input
                        class="mb-2 mr-sm-2 mb-sm-0"
                        type="text"
                        :state="v$.bookingPreset.name.$error ? false : null"
                        v-model="bookingPreset.name"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="!v$.bookingPreset.name.$error"
                      >
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 名稱 End -->
                    <!-- 類型 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="*預約設定檔類型"
                    >
                      <b-form-radio-group
                        v-model="bookingPreset.type"
                        :options="bookingPresetTypes"
                      ></b-form-radio-group>
                    </b-form-group>
                    <!-- 類型 End -->
                    <!-- 描述 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="預約設定檔說明"
                    >
                      <b-form-textarea
                        class="mb-2 mr-sm-2 mb-sm-0"
                        v-model="bookingPreset.config.description"
                      ></b-form-textarea>
                    </b-form-group>
                    <!-- 描述 End -->
                    <!-- 瀏覽器紀錄 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="是否開放預約"
                      description="是否允許使用者新增預約"
                    >
                      <b-form-select
                        v-model="bookingPreset.status"
                      >
                        <b-form-select-option value="draft">草稿</b-form-select-option>
                        <b-form-select-option value="published">公開</b-form-select-option>
                        <b-form-select-option value="closed">關閉</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                    <!-- 瀏覽器紀錄 End -->
                    <!-- 表單開始時間 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="* 開始時間"
                    >
                      <div class="row">
                        <div class="col-12 col-xl-6">
                          <datepicker
                            placeholder="選擇日期"
                            v-model="bookingPreset.start_at.date"
                            bootstrap-styling
                            format="yyyy-MM-dd"
                            :language="zh"
                          ></datepicker>
                          <b-form-invalid-feedback
                            :state="!v$.bookingPreset.start_at.date.required.$error"
                          >
                            此欄位為必填
                          </b-form-invalid-feedback>
                        </div>

                        <div class="col-12 col-xl-6">
                          <vue-timepicker
                            placeholder="選擇時間"
                            format="HH:mm"
                            v-model="bookingPreset.start_at.time"
                            :input-class="['form-control']"
                          ></vue-timepicker>
                          <b-form-invalid-feedback
                            :state="
                              !v$.bookingPreset.start_at.time.HH.$error ||
                              !v$.bookingPreset.start_at.time.mm.$error
                            "
                          >
                            此欄位為必填
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </b-form-group>
                    <!-- 表單開始時間 End -->
                    <!-- 表單結束時間 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="* 結束時間"
                    >
                      <div class="row">
                        <div class="col-12 col-xl-6">
                          <datepicker
                            placeholder="選擇日期"
                            v-model="bookingPreset.end_at.date"
                            bootstrap-styling
                            format="yyyy-MM-dd"
                            :language="zh"
                            :input-class="v$.bookingPreset.start_at.$invalid ? 'is-invalid' : ''"
                          ></datepicker>
                          <b-form-invalid-feedback :state="!v$.bookingPreset.end_at.date.$error">
                            此欄位為必填
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback
                            :state="!v$.bookingPreset.start_at.date.beforeEndAt.$invalid"
                          >
                            結束時間必須大於起始時間
                          </b-form-invalid-feedback>
                        </div>

                        <div class="col-12 col-xl-6">
                          <vue-timepicker
                            placeholder="選擇時間"
                            :input-class="[
                              'form-control',
                              {
                                'is-invalid':
                                  v$.bookingPreset.start_at.time.HH.$error ||
                                  v$.bookingPreset.start_at.time.mm.$error ||
                                  v$.bookingPreset.start_at.date.beforeEndAt.$invalid,
                              },
                            ]"
                            v-model="bookingPreset.end_at.time"
                          ></vue-timepicker>
                          <b-form-invalid-feedback
                            :state="
                              !v$.bookingPreset.end_at.time.HH.$error || !v$.bookingPreset.end_at.time.mm.$error
                            "
                          >
                            此欄位為必填
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </b-form-group>
                    <!-- 表單結束時間 End -->
                  </div>
                </div>
                <!-- 欄位部分 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button @click="submit" variant="success"> 儲存 </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 基本資料 section end -->

              <!-- 歡迎頁 -->
              <section v-if="step == '#welcome' && bookingPreset.config.welcome_page">
                <h4 class="mb-3 font-weight-bold">歡迎頁</h4>
                <validation-error-alert
                  v-if="validationErrors"
                  :errors="validationErrors"
                ></validation-error-alert>
                <!-- 啟用 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="啟用"
                >
                  <b-form-checkbox v-model="bookingPreset.config.welcome_page.enable">啟用</b-form-checkbox>
                </b-form-group>
                <!-- 啟用 End -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                >
                  <template #label>
                    主圖
                    <p class="text-black-50 mt-2" style="font-size: .75rem">寬 x 高：1400px x 不限</p>
                  </template>
                  <template #default>
                    <AppFileUploader
                      v-model="bookingPreset.config.welcome_page.banner_image"
                      placeholder-when-uploaded="（查看檔案）"
                      placeholder-when-empty=""
                    />
                  </template>
                </b-form-group>
                <!-- 標題 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="標題"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :disabled="!bookingPreset.config.welcome_page.enable"
                    v-model="bookingPreset.config.welcome_page.form_title"
                  ></b-form-input>
                </b-form-group>
                <!-- 標題 End -->
                <!-- 副標題 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="副標題"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :disabled="!bookingPreset.config.welcome_page.enable"
                    v-model="bookingPreset.config.welcome_page.form_desc"
                  ></b-form-input>
                </b-form-group>
                <!-- 副標題 End -->
                <!-- 內容說明 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="內容說明"
                >
                  <TrixEditor :readonly="!bookingPreset.config.welcome_page.enable" v-model="bookingPreset.config.welcome_page.content" />
                </b-form-group>
                <!-- 內容說明 End -->
                <!-- 按鈕文字 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="按鈕文字"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :disabled="!bookingPreset.config.welcome_page.enable"
                    v-model="bookingPreset.config.welcome_page.button_text"
                  ></b-form-input>
                </b-form-group>
                <!-- 按鈕文字 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>

              <!-- 內容頁 -->
              <section v-if="step == '#content' && bookingPreset.config.content_page">
                <h4 class="mb-3 font-weight-bold">內容頁</h4>
                <validation-error-alert
                  v-if="validationErrors"
                  :errors="validationErrors"
                ></validation-error-alert>
                <!-- 每頁都顯示 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="每頁都顯示"
                >
                  <b-form-checkbox v-model="bookingPreset.config.content_page.displayed_in_every_page">每頁都顯示</b-form-checkbox>
                </b-form-group>
                <!-- 每頁都顯示 End -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                >
                  <template #label>
                    主圖
                    <p class="text-black-50 mt-2" style="font-size: .75rem">寬 x 高：1400px x 不限</p>
                  </template>
                  <template #default>
                    <AppFileUploader
                      v-model="bookingPreset.config.content_page.banner_image"
                      placeholder-when-uploaded="（查看檔案）"
                      placeholder-when-empty=""
                    />
                  </template>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                >
                  <template #label>
                    背景底圖
                    <p class="text-black-50 mt-2" style="font-size: .75rem">寬 x 高：1400px x 不限</p>
                  </template>
                  <template #default>
                    <AppFileUploader
                      v-model="bookingPreset.config.content_page.background_image"
                      placeholder-when-uploaded="（查看檔案）"
                      placeholder-when-empty=""
                    />
                  </template>
                </b-form-group>
                <!-- 標題 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="標題"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="bookingPreset.config.content_page.form_title"
                  ></b-form-input>
                </b-form-group>
                <!-- 標題 End -->
                <!-- 副標題 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="副標題"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="bookingPreset.config.content_page.form_desc"
                  ></b-form-input>
                </b-form-group>
                <!-- 副標題 End -->
                <!-- 內容說明 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="內容說明"
                >
                  <TrixEditor v-model="bookingPreset.config.content_page.content"/>
                </b-form-group>
                <!-- 內容說明 End -->
                <!-- 送出按鈕文字 Start -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  label-size="sm"
                  label="送出按鈕文字"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="bookingPreset.config.content_page.submit_button_text"
                  ></b-form-input>
                </b-form-group>
                <!-- 送出按鈕文字 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>

              <!-- 完成頁 -->
              <section v-if="step == '#finished' && bookingPreset.config.finished_page">
                <h4 class="mb-3 font-weight-bold">完成頁</h4>
                    <!-- 繼續預約 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="顯示繼續預約按鈕"
                    >
                      <b-form-checkbox
                        v-model="bookingPreset.config.finished_page.enabled_next_booking_button"
                      ></b-form-checkbox>
                    </b-form-group>
                    <!-- 繼續預約 End -->
                    <!-- 預約紀錄 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="顯示預約紀錄按鈕"
                    >
                      <b-form-checkbox
                        v-model="bookingPreset.config.finished_page.enabled_go_booking_list_button"
                      ></b-form-checkbox>
                    </b-form-group>
                    <!-- 預約紀錄 End -->
                    <!-- 內容說明 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="內容說明"
                    >
                      <TrixEditor v-model="bookingPreset.config.finished_page.content"/>
                    </b-form-group>
                    <!-- 內容說明 End -->
                    <!-- 主圖 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                    >
                      <template #label>
                        主圖
                        <p class="text-black-50 mt-2" style="font-size: .75rem">寬 x 高：1400px x 不限</p>
                      </template>
                      <template #default>
                        <AppFileUploader
                          v-model="bookingPreset.config.finished_page.banner_image"
                          placeholder-when-uploaded="（查看檔案）"
                          placeholder-when-empty=""
                        />
                      </template>
                    </b-form-group>
                    <!-- 主圖 End -->
                  <!-- 表單底部 Start -->
                  <div class="d-flex justify-content-center">
                    <b-button
                      class="mr-3"
                      @click="cancel"
                      variant="outline-danger"
                    >
                      返回
                    </b-button>
                    <b-button
                      @click="submit"
                      variant="success"
                    >
                      儲存
                    </b-button>
                  </div>
                  <!-- 表單底部 End -->
              </section>

              <!-- 欄位設定 section start -->
              <section v-if="step == '#fields'">
                <NameBlock :label="'預約設定檔名稱'" :name="bookingPreset.name" />
                <div>
                  <DynamicFormEditor
                    v-if="bookingPreset.id"
                    @output="saveFields"
                    @cancel="$router.push({ name: 'BookingPresetList' })"
                    editor-title="欄位設定"
                    :form-type="'booking'"
                    :input-subjects="fields"
                    :pages="bookingPreset.config.pages"
                    :sections="bookingPreset.config.sections"
                    :validate-subject="validSubject"
                    validation-message="標題不得為空"
                    enable-visible-condition
                  ></DynamicFormEditor>
                </div>
              </section>
              <!-- 欄位設定 section end -->

              <!-- 預約資料設定 section start -->
              <section v-if="step == '#mapping'">
                <NameBlock :label="'預約設定檔名稱'" :name="bookingPreset.name"/>
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-12 mb-3 font-weight-bold">
                    預約資料設定
                  </h4>
                  <h5 class="col-12 col-xl-12 mb-3 font-weight-bold">
                    欄位對應
                  </h5>
                  <div class="col-12 col-xl-8">
                    <!-- 標題 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="標題顯示欄位"
                    >
                      <b-form-select
                        v-model="
                          bookingPreset.config.booking_mapping.bookings.title
                        "
                        :options="titleOptions"
                      ></b-form-select>
                    </b-form-group>
                    <!-- 標題 End -->
                    <!-- 預約開始欄位 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="預約開始欄位"
                    >
                      <b-form-select
                        v-model="
                          bookingPreset.config.booking_mapping.bookings.start_at
                        "
                        :options="dateFieldCandidates"
                      ></b-form-select>
                    </b-form-group>
                    <!-- 預約開始欄位 End -->
                    <!-- 預約結束欄位 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="預約結束欄位"
                    >
                      <b-form-select
                        v-model="
                          bookingPreset.config.booking_mapping.bookings.end_at
                        "
                        :options="dateFieldCandidates"
                        :disabled="bookingPreset.type == 'wizard'"
                      ></b-form-select>
                    </b-form-group>
                    <!-- 預約結束欄位 End -->
                    <!-- 結束時間區段 Start-->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="結束時間區段"
                    >
                      <b-form-input
                        v-model="
                          bookingPreset.config.booking_mapping.bookings.end_slot_minutes
                        "
                        placeholder="請填入分鐘"
                        type="number"
                        min="0"
                      ></b-form-input>
                    </b-form-group>
                    <!-- 結束時間區段 End-->
                    <!-- 預約開始條件 Start -->
                     <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="預約開始條件(分店)"
                    >
                      <b-form-select
                        v-model="
                          bookingPreset.config.booking_mapping.bookings.start_at_by_branch
                        "
                        :options="branchFieldCandidates"
                        :disabled="!bookingPreset.config.booking_mapping.bookings.start_at"
                      ></b-form-select>
                    </b-form-group>
                    <!-- 預約開始條件 End -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="最後服務時間"
                    >
                      <AppTimePicker
                        styleType="unlimited"
                        v-model="bookingPreset.config.booking_mapping.bookings.last_serve_time"
                      />
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="分店欄位"
                    >
                      <b-form-select
                        v-model="
                          bookingPreset.config.branch.field_id
                        "
                        :options="branchFieldCandidates"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <h5 class="col-12 col-xl-12 mb-3 font-weight-bold">
                    預約狀態
                  </h5>
                  <div class="col-12 col-xl-8">
                    <!-- 預約狀態欄位 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="狀態欄位"
                    >
                      <b-form-select
                        v-model="
                          bookingPreset.config.booking_mapping.bookings.status
                        "
                        :options="collectionFieldCandidates"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="狀態預設值"
                    >
                      <b-form-input
                        class="mb-2 mr-sm-2 mb-sm-0"
                        type="text"
                        v-model="
                          bookingPreset.config.booking_mapping
                            .default_status_value
                        "
                      ></b-form-input>
                    </b-form-group>
                    <!-- 預約狀態對應 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="狀態文字對應"
                    >
                      <JsonEditor
                        height="128px"
                        v-model="
                          bookingPreset.config.provider_config.status_filter_mapping
                        "
                        ref="statusFilterMapping"
                      />
                      <b-form-invalid-feedback
                        :state="
                          !v$.bookingPreset.config.provider_config.status_filter_mapping.$error
                        "
                      >
                        {{
                          presentValidationErrors(
                            "bookingPreset.config.provider_config.status_filter_mapping"
                          )
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 預約狀態對應 End -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="狀態顯示編輯按鈕"
                    >
                      <JsonEditor
                        height="64px"
                        v-model="bookingPreset.config.can_edit_at_liff_mapping"
                        ref="editLiffMapping"
                      />
                      <b-form-invalid-feedback
                        :state="!v$.bookingPreset.config.can_edit_at_liff_mapping.$error"
                      >
                        {{
                          presentValidationErrors(
                            "bookingPreset.config.can_edit_at_liff_mapping"
                          )
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 預約狀態欄位 End -->
                  </div>
                </div>
                <!-- 欄位部分 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button @click="submit" variant="success"> 儲存 </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 預約資料設定 section end -->

              <!-- 預約資料設定 section start -->
              <section v-if="step == '#wizard-mode'">
                <div class="row d-flex mb-4 mb-xl-2">
                  <div class="col-12 col-xl-12">
                    <NameBlock :label="'預約設定檔名稱'" :name="bookingPreset.name"/>
                  </div>
                  <h4 class="col-12 col-xl-12 mb-3 font-weight-bold">
                    精靈模式設定
                  </h4>
                  <h5 class="col-12 col-xl-12 mb-3 font-weight-bold">
                    分店設定
                  </h5>
                  <div class="col-12 col-xl-8">
                    <!-- 分店區 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="啟用選擇分店"
                    >
                      <b-form-checkbox
                        v-model="bookingPreset.config.enabled_selected_branch"
                      ></b-form-checkbox>
                    </b-form-group>
                    <!-- 分店區 End -->
                  </div>
                  <hr />
                  <h5 class="col-12 col-xl-12 mb-3 font-weight-bold">
                    員工設定
                  </h5>
                  <div class="col-12 col-xl-8">
                    <!-- 員工區 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="啟用選擇員工"
                    >
                      <b-form-checkbox
                        v-model="bookingPreset.config.enabled_selected_staff"
                      ></b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="啟用員工班表設定"
                    >
                      <b-form-checkbox
                        v-model="bookingPreset.config.enabled_selected_staff_shift"
                      ></b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="啟用員工服務關聯"
                    >
                      <b-form-checkbox
                        v-model="bookingPreset.config.enabled_staff_service_relation"
                      ></b-form-checkbox>
                    </b-form-group>
                    <!-- 員工區 End -->
                    <hr />
                  </div>
                  <h5 class="col-12 col-xl-12 mb-3 font-weight-bold">
                    服務設定
                  </h5>
                  <div class="col-12 col-xl-8">
                    <!-- 服務區 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="服務項目欄位"
                    >
                      <b-form-select
                        v-model="bookingPreset.config.service.collection_id"
                        :options="serviceFieldCandidates"
                        @change="changeService"
                      ></b-form-select>
                    </b-form-group>
                    <!-- 精靈模式 service特殊設定欄位 -->
                    <div v-if="bookingPreset.config.service.collection_id">
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-size="sm"
                        label="圖片欄位"
                      >
                        <b-form-select
                          v-model="bookingPreset.config.service.image_item_id"
                          :disabled="!bookingPreset.config.service.collection_id"
                          :options="collectionItemOptions(bookingPreset.config.service.collection_id)"
                        >
                        </b-form-select>
                      </b-form-group>
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-size="sm"
                        label="標題欄位"
                      >
                        <b-form-select
                          v-model="bookingPreset.config.service.title_item_id"
                          :disabled="!bookingPreset.config.service.collection_id"
                          :state="!v$.bookingPreset.config.service.title_item_id.$error"
                          :options="collectionItemOptions(bookingPreset.config.service.collection_id)"
                        >
                        </b-form-select>
                        <b-form-invalid-feedback :state="!v$.bookingPreset.config.service.title_item_id.$error">
                          {{ presentValidationErrors('bookingPreset.config.service.title_item_id') }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-size="sm"
                        label="敘述欄位"
                      >
                        <b-form-select
                          v-model="
                            bookingPreset.config.service.description_item_id
                          "
                          :disabled="!bookingPreset.config.service.collection_id"
                          :options="collectionItemOptions(bookingPreset.config.service.collection_id)"
                        >
                        </b-form-select>
                      </b-form-group>
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-size="sm"
                        label="價格欄位"
                      >
                        <b-form-select
                          v-model="bookingPreset.config.service.price_item_id"
                          :disabled="!bookingPreset.config.service.collection_id"
                          :options="collectionItemOptions(bookingPreset.config.service.collection_id)"
                        >
                        </b-form-select>
                      </b-form-group>
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-size="sm"
                        label="時間欄位"
                      >
                        <b-form-select
                          v-model="
                            bookingPreset.config.service.duration_item_id
                          "
                          :disabled="!bookingPreset.config.service.collection_id"
                          :options="collectionItemOptions(bookingPreset.config.service.collection_id)"
                        >
                        </b-form-select>
                      </b-form-group>
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-size="sm"
                        label="允許多選服務"
                      >
                        <b-form-checkbox
                          v-model="bookingPreset.config.service.can_multiple_select"
                          :disabled="!bookingPreset.config.service.collection_id"
                        >
                        </b-form-checkbox>
                      </b-form-group>
                      <!-- 服務區 End -->
                    </div>
                    <hr />
                    <h5 class="col-12 col-xl-12 mb-3 font-weight-bold">
                      流程設定
                    </h5>
                    <div class="col-12 col-xl-8">
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-size="sm"
                        label="流程順序 - 分店"
                      >
                        <b-form-select
                          v-model="bookingPreset.config.order.branch_page"
                          :options="orderOptions"
                        >
                        </b-form-select>
                      </b-form-group>
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-size="sm"
                        label="流程順序 - 員工"
                      >
                        <b-form-select
                          v-model="bookingPreset.config.order.staff_page"
                          :options="orderOptions"
                        >
                        </b-form-select>
                      </b-form-group>
                      <b-form-group
                        label-cols="12"
                        label-cols-lg="2"
                        label-size="sm"
                        label="流程順序 - 服務"
                      >
                        <b-form-select
                          v-model="bookingPreset.config.order.service_page"
                          :options="orderOptions"
                        >
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <!-- 精靈模式 service特殊設定欄位 -->
                  </div>
                </div>
                <!-- 欄位部分 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button @click="submit" variant="success"> 儲存 </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 預約資料設定 section end -->

              <!-- liff預約紀錄 section start -->
              <section v-if="step == '#liff-setting'">
                <!-- 欄位部分 Start -->
                <div class="row d-flex mb-4 mb-xl-2">
                  <div class="col-12 col-xl-12">
                    <NameBlock :label="'預約設定檔名稱'" :name="bookingPreset.name"/>
                  </div>
                  <h5 class="col-12 col-xl-12 mb-3 font-weight-bold">
                    LIFF 預約紀錄
                  </h5>
                  <div class="col-12 col-xl-8">
                    <!-- 預約記錄頁籤 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="預約記錄頁籤"
                    >
                      <JsonEditor
                        height="128px"
                        v-model="
                          bookingPreset.config.provider_config.booking_status_mapping
                        "
                        ref="bookingStatusMapping"
                      />
                      <b-form-invalid-feedback
                        :state="
                          !v$.bookingPreset.config.provider_config.booking_status_mapping.$error
                        "
                      >
                        {{
                          presentValidationErrors(
                            "bookingPreset.config.provider_config.booking_status_mapping"
                          )
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 預約記錄頁籤 End -->
                    <!-- 清單列表欄位 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="清單列表欄位"
                    >
                      <JsonEditor
                        height="128px"
                        v-model="bookingPreset.config.liff_field_setting.list"
                        ref="liffFieldSetting"
                      />
                      <b-form-invalid-feedback
                        :state="
                          !v$.bookingPreset.config.liff_field_setting.list
                            .$error
                        "
                      >
                        {{
                          presentValidationErrors(
                            "bookingPreset.config.liff_field_setting.list"
                          )
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 清單列表欄位 End -->
                    <!-- 明細頁狀態變更按鈕 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="明細頁狀態變更按鈕"
                    >
                      <JsonEditor
                        height="128px"
                        v-model="bookingPreset.config.status_detector"
                        ref="statusDetector"
                      />
                      <b-form-invalid-feedback
                        :state="!v$.bookingPreset.config.status_detector.$error"
                      >
                        {{
                          presentValidationErrors(
                            "bookingPreset.config.status_detector"
                          )
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 明細頁狀態變更按鈕 End -->
                  </div>
                </div>
                <!-- 欄位部分 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button @click="submit" variant="success"> 儲存 </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- liff預約紀錄 section end -->

              <!-- 預約設定 section start -->
              <section v-if="step == '#booking-setting'">
                <!-- 欄位部分 Start -->
                <div class="row d-flex mb-4 mb-xl-2">
                  <div class="col-12 col-xl-12">
                    <NameBlock :label="'預約設定檔名稱'" :name="bookingPreset.name"/>
                  </div>
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">
                    推播範本
                  </h4>
                  <div class="col-12 col-xl-8">
                    <validation-error-alert
                      v-if="validationErrors"
                      :errors="validationErrors"
                    ></validation-error-alert>
                    <!-- 填寫後通知 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                      label="填寫後貼標"
                    >
                      <TagsBox
                        v-model="
                          bookingPreset.config.event_setting.booking_tags
                        "
                      ></TagsBox>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                      label="表頭背景色"
                    >
                      <color-picker v-model="bookingPreset.config.push_template.header_bg_color"></color-picker>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                      label="按鈕背景色"
                    >
                      <color-picker v-model="bookingPreset.config.push_template.button_bg_color"></color-picker>
                    </b-form-group>
                  </div>
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">
                    預約後推播
                  </h4>
                  <div class="col-12 col-xl-8">
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                    >
                      <b-form-checkbox v-model="bookingPreset.config.created_push_message">啟用預約後立即推播</b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                    >
                      <template #label>
                        表頭圖片
                        <p class="text-black-50 mt-2" style="font-size: .75rem">寬 x 高：1400px x 不限</p>
                      </template>
                      <template #default>
                        <AppFileUploader
                          v-model="bookingPreset.config.created_push_header_image_url"
                          placeholder-when-empty=""
                        />
                      </template>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                      label="推播文字"
                    >
                      <b-form-textarea
                        class="mb-2 mr-sm-2 mb-sm-0"
                        v-model="bookingPreset.config.created_push_message_content"
                        rows="4"
                      ></b-form-textarea>
                    </b-form-group>
                  </div>
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">
                    確認後推播
                  </h4>
                  <div class="col-12 col-xl-8">
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                    >
                      <b-form-checkbox v-model="bookingPreset.config.notify.confirmed.enabled">啟用確認後推播</b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                    >
                      <template #label>
                        表頭圖片
                        <p class="text-black-50 mt-2" style="font-size: .75rem">寬 x 高：1400px x 不限</p>
                      </template>
                      <template #default>
                        <AppFileUploader
                          v-model="bookingPreset.config.confirmed_push_header_image_url"
                          placeholder-when-empty=""
                        />
                      </template>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                      label="推播文字"
                    >
                      <b-form-textarea
                        class="mb-2 mr-sm-2 mb-sm-0"
                        v-model="bookingPreset.config.confirmed_push_message_content"
                        rows="4"
                      ></b-form-textarea>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                      label="狀態定義"
                    >
                      <JsonEditor
                        height="64px"
                        v-model="bookingPreset.config.notify.confirmed.status_mapping"
                        ref="confirmedStatusMappingRef"
                      ></JsonEditor>
                      <b-form-invalid-feedback :state="!v$.bookingPreset.config.notify.confirmed.status_mapping.$error">
                        {{presentValidationErrors("bookingPreset.config.confirmed_push_message_content")}}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">
                    完成後推播
                  </h4>
                  <div class="col-12 col-xl-8">
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                    >
                      <b-form-checkbox v-model="bookingPreset.config.notify.completed.enabled">啟用完成後推播</b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                    >
                      <template #label>
                        表頭圖片
                        <p class="text-black-50 mt-2" style="font-size: .75rem">寬 x 高：1400px x 不限</p>
                      </template>
                      <template #default>
                        <AppFileUploader
                          v-model="bookingPreset.config.completed_push_header_image_url"
                          placeholder-when-empty=""
                        />
                      </template>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                      label="推播文字"
                    >
                      <b-form-textarea
                        class="mb-2 mr-sm-2 mb-sm-0"
                        v-model="bookingPreset.config.completed_push_message_content"
                        rows="4"
                      ></b-form-textarea>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                      label="狀態定義"
                    >
                      <JsonEditor
                        height="64px"
                        v-model="bookingPreset.config.notify.completed.status_mapping"
                        ref="cancelNotifyRef"
                      ></JsonEditor>
                    </b-form-group>
                  </div>
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">
                    取消後推播
                  </h4>
                  <div class="col-12 col-xl-8">
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                    >
                      <b-form-checkbox v-model="bookingPreset.config.notify.cancel.enabled">啟用取消後推播</b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                    >
                      <template #label>
                        表頭圖片
                        <p class="text-black-50 mt-2" style="font-size: .75rem">寬 x 高：1400px x 不限</p>
                      </template>
                      <template #default>
                        <AppFileUploader
                          v-model="bookingPreset.config.canceled_push_header_image_url"
                          placeholder-when-empty=""
                        />
                      </template>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                      label="推播文字"
                    >
                      <b-form-textarea
                        class="mb-2 mr-sm-2 mb-sm-0"
                        v-model="bookingPreset.config.canceled_push_message_content"
                        rows="4"
                      ></b-form-textarea>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                      label="狀態定義"
                    >
                      <JsonEditor
                        height="64px"
                        v-model="bookingPreset.config.notify.cancel.status_mapping"
                        ref="cancelNotifyRef"
                      ></JsonEditor>
                    </b-form-group>
                  </div>
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">
                    修改後推播
                  </h4>
                  <div class="col-12 col-xl-8">
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                    >
                      <b-form-checkbox v-model="bookingPreset.config.notify.edit.enabled">啟用修改後推播</b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                    >
                      <template #label>
                        表頭圖片
                        <p class="text-black-50 mt-2" style="font-size: .75rem">寬 x 高：1400px x 不限</p>
                      </template>
                      <template #default>
                        <AppFileUploader
                          v-model="bookingPreset.config.modified_push_header_image_url"
                          placeholder-when-empty=""
                        />
                      </template>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                      label="推播文字"
                    >
                      <b-form-textarea
                        class="mb-2 mr-sm-2 mb-sm-0"
                        v-model="bookingPreset.config.modified_push_message_content"
                        rows="4"
                      ></b-form-textarea>
                    </b-form-group>
                  </div>
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">
                    每日提醒推播
                  </h4>
                  <div class="col-12 col-xl-8">
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                      label="推播設定"
                    >
                      <JsonEditor
                        height="128px"
                        v-model="bookingPreset.config.daily_booking_reminder"
                        ref="dailyBookingReminder"
                      />
                      <b-form-invalid-feedback :state="!v$.bookingPreset.config.daily_booking_reminder.$error">
                        {{ presentValidationErrors('bookingPreset.config.daily_booking_reminder') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 填寫後通知 End -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                      label="推播文字"
                    >
                      <b-form-textarea
                        class="mb-2 mr-sm-2 mb-sm-0"
                        v-model="bookingPreset.config.every_day_message"
                        rows="4"
                      ></b-form-textarea>
                    </b-form-group>

                  </div>
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">
                    後台推播設定
                  </h4>
                  <div class="col-12 col-xl-8">
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="3"
                      label-size="sm"
                      label="狀態變更設定"
                    >
                      <JsonEditor
                        height="128px"
                        v-model="bookingPreset.config.status_change_events"
                        ref="statusChangeEvent"
                      />
                      <b-form-invalid-feedback
                        :state="
                          !v$.bookingPreset.config.status_change_events.$error
                        "
                      >
                        {{
                          presentValidationErrors(
                            "bookingPreset.config.status_change_events"
                          )
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>
                <!-- 欄位部分 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button @click="submit" variant="success"> 儲存 </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 預約設定 section end -->

              <!-- 分頁與分區設定 section start -->
              <section v-if="step == '#pages-and-sections'">
                <NameBlock :label="'預約設定檔名稱'" :name="bookingPreset.name"/>
                <PageAndSectionEditor
                  editor-title="分頁與分區設定"
                  :inputSubjects="bookingPreset.config.fields"
                  :pages.sync="bookingPreset.config.pages"
                  :sections.sync="bookingPreset.config.sections"
                  expand-slot-area
                  @output="handlePageAndSectionUpdate"
                  @cancel="cancel"
                />
              </section>
              <!-- 分頁與分區設定 section end -->

              <!-- 欄位資訊 section start -->
              <section v-if="step == '#collection-info'">
                <NameBlock :label="'預約設定檔名稱'" :name="bookingPreset.name"/>
                <h4 class="font-weight-bold">欄位資訊</h4>
                <DynamicFormReadOnly
                  v-if="bookingPreset.id"
                  :input-subjects="bookingPreset.config.fields"
                  @cancel="$router.push({ name: 'BookingPresetList' })"
                ></DynamicFormReadOnly>
              </section>

              <!-- 估價單設定 section start -->
              <section
                v-if="
                  step == '#booking-list-quotation-setting' &&
                  checkPermission([consts.BOOKING_LIST_QUOTATION_SETTING])
                "
              >
                <NameBlock :label="'預約設定檔名稱'" :name="bookingPreset.name"/>

                <h4 class="font-weight-bold">估價單設定</h4>
                <div class="col-12 col-xl-12">
                  <validation-error-alert
                    v-if="validationErrors"
                    :errors="validationErrors"
                  ></validation-error-alert>
                  <!-- 表頭設定 Start -->
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="12"
                    label-size="sm"
                    label="表頭設定"
                  >
                    <b-form-textarea
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="bookingPreset.config.quotation_header"
                      rows="10"
                    ></b-form-textarea>
                  </b-form-group>
                  <!-- 表頭設定 End -->
                  <!-- 內容表頭設定 Start -->
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="12"
                    label-size="sm"
                  >
                    <span id="quotation-content-header-tooltip">
                      內容表頭設定
                      <span class="mdi mdi-information" />
                    </span>
                    <b-tooltip placement="top" target="quotation-content-header-tooltip">
                      <template #default>
                        <div style="text-align: left">
                          <span>優先出現field_id，對應的文字，如果不存在或為空，則出現text中的文字</span><br />
                          <span>{</span><br />
                          <span>"text": "純文字",</span><br />
                          <span>"style": "顯示的style",</span><br />
                          <span>"colspan": "所佔的格數，數字表示",</span><br />
                          <span>"field_id": "data的field_id，會轉換成對應的文字"</span><br />
                          <span>}</span><br />
                        </div>
                      </template>
                    </b-tooltip>
                    <JsonEditor
                      height="256px"
                      v-model="bookingPreset.config.quotation_content_header"
                      ref="quotationContentHeaderManagement"
                    />
                    <b-form-invalid-feedback :state="!v$.bookingPreset.config.quotation_content_header.$error">
                      {{ presentValidationErrors('bookingPreset.config.quotation_content_header') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <!-- 內容表頭設定 End -->
                  <!-- 內容設定 Start -->
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="12"
                    label-size="sm"
                  >
                    <span id="quotation-content-tooltip">
                      內容設定
                      <span class="mdi mdi-information" />
                    </span>
                    <b-tooltip placement="top" target="quotation-content-tooltip">
                      <template #default>
                        <div style="text-align: left">
                          <span>優先出現field_id，對應的文字，如果不存在或為空，則出現text中的文字</span><br />
                          <span>{</span><br />
                          <span>"text": "純文字",</span><br />
                          <span>"style": "顯示的style",</span><br />
                          <span>"colspan": "所佔的格數，數字表示",</span><br />
                          <span>"field_id": "data的field_id，會轉換成對應的文字"</span><br />
                          <span>}</span><br />
                        </div>
                      </template>
                    </b-tooltip>
                    <JsonEditor
                      height="256px"
                      v-model="bookingPreset.config.quotation_content"
                      ref="quotationContentManagement"
                    />
                    <b-form-invalid-feedback :state="!v$.bookingPreset.config.quotation_content.$error">
                      {{ presentValidationErrors('bookingPreset.config.quotation_content') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <!-- 內容設定 End -->
                  <!-- 內容頁尾設定 Start -->
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="12"
                    label-size="sm"
                  >
                    <span id="quotation-content-footer-tooltip">
                      內容頁尾設定
                      <span class="mdi mdi-information" />
                    </span>
                    <b-tooltip placement="top" target="quotation-content-footer-tooltip">
                      <template #default>
                        <div style="text-align: left">
                          <span>優先出現field_id，對應的文字，如果不存在或為空，則出現text中的文字</span><br />
                          <span>{</span><br />
                          <span>"text": "純文字",</span><br />
                          <span>"style": "顯示的style",</span><br />
                          <span>"colspan": "所佔的格數，數字表示",</span><br />
                          <span>"field_id": "data的field_id，會轉換成對應的文字"</span><br />
                          <span>}</span><br />
                        </div>
                      </template>
                    </b-tooltip>
                    <JsonEditor
                      height="256px"
                      v-model="bookingPreset.config.quotation_content_footer"
                      ref="quotationContentFooterManagement"
                    />
                    <b-form-invalid-feedback :state="!v$.bookingPreset.config.quotation_content_footer.$error">
                      {{ presentValidationErrors('bookingPreset.config.quotation_content_footer') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <!-- 內容頁尾設定 End -->
                  <!-- 頁尾設定 Start -->
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="12"
                    label-size="sm"
                    label="頁尾設定"
                  >
                    <b-form-textarea
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="bookingPreset.config.quotation_footer"
                      rows="10"
                    ></b-form-textarea>
                  </b-form-group>
                  <!-- 頁尾設定 End -->
                  <!-- 表單底部 Start -->
                  <div class="d-flex justify-content-center mt-2">
                    <b-button
                      class="mr-3"
                      @click="cancel"
                      variant="outline-danger"
                    >
                      返回
                    </b-button>
                    <b-button @click="submit" variant="success">
                      儲存
                    </b-button>
                  </div>
                  <!-- 表單底部 End -->
                </div>
              </section>

              <section v-if="step == '#limit-setting'">
                <NameBlock :label="'預約設定檔名稱'" :name="bookingPreset.name"/>
                <b-form-checkbox
                  v-model="bookingPreset.config.occupy_config.enable"
                  :disabled="!bookingPreset.config.booking_mapping.bookings.start_at"
                ><h4>啟用預約次數上限控制</h4></b-form-checkbox>
                <ul class="list-none">
                  <li>
                    <b-form-checkbox
                      v-model="bookingPreset.config.occupy_config.enable"
                      :disabled="!bookingPreset.config.occupy_config.enable"
                    >預約時段</b-form-checkbox>
                  </li>
                  <li>
                    <b-form-checkbox
                      v-model="bookingPreset.config.occupy_config.is_check_collection"
                      :disabled="!bookingPreset.config.occupy_config.enable"
                    >
                      <b-form-select
                        v-model="bookingPreset.config.occupy_config.collection_id"
                        :options="collectionOptions"
                        :disabled="!bookingPreset.config.occupy_config.is_check_collection"
                      />
                    </b-form-checkbox>
                  </li>
                  <li>
                    <b-form-checkbox
                      v-model="bookingPreset.config.occupy_config.is_check_branch"
                      :disabled="!bookingPreset.config.occupy_config.enable"
                    >分店</b-form-checkbox>
                  </li>
                  <li>
                    <b-form-checkbox
                      v-model="bookingPreset.config.occupy_config.is_check_staff"
                      :disabled="!bookingPreset.config.occupy_config.enable"
                    >員工</b-form-checkbox>
                  </li>
                  <li>
                    <b-form-checkbox
                      v-model="bookingPreset.config.occupy_config.is_check_service"
                      :disabled="!bookingPreset.config.occupy_config.enable"
                    >服務項目</b-form-checkbox>
                  </li>
                </ul>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="納入預約次數的狀態"
                >
                  <JsonEditor
                    height="32px"
                    v-model="bookingPreset.config.occupy_config.occupy_status"
                    :disabled="!bookingPreset.config.occupy_config.enable"
                  />
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="預約上限"
                >
                  <b-form-radio-group v-model="bookingPreset.config.occupy_config.limit_type" class="pt-3">
                    <b-form-radio value="collection" class="form-inline mb-2 col-12" :disabled="!bookingPreset.config.occupy_config.is_check_collection">
                      <b-form-select
                        v-model="bookingPreset.config.occupy_config.collection_id"
                        :options="collectionOptions"
                        :disabled="true"
                      />
                      <b-form-select
                        class="ml-2"
                        v-model="bookingPreset.config.occupy_config.collection_field_id"
                        :options="collectionItemOptions(bookingPreset.config.occupy_config.collection_id)"
                        :disabled="!(bookingPreset.config.occupy_config.limit_type == 'collection')"
                      />
                    </b-form-radio>
                    <b-form-radio value="time" class="form-inline mb-2 col-12" @click="generateTimeOptions" :disabled="!limitTimeField">
                      依時段設定
                      時段限定
                      <input
                        class="mb-2 mr-sm-2 mb-sm-0 w-4"
                        type="text"
                        v-model="bookingPreset.config.occupy_config.slot_people"
                        @input="generateTimeOptions"
                      /> 人
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label=""
                >
                  <div v-if="limitTimeField.format == 'datetime'">
                    <div v-for="(timeOption, index) in bookingPreset.config.occupy_config.time_options" v-bind:key="index">
                      <b-form-group
                        label-cols="6"
                        label-cols-lg="6"
                        label-size="sm"
                        :label="timeOption.title"
                      >
                        <b-form-input v-model="timeOption.value"></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                </b-form-group>
                <div class="d-flex justify-content-center mt-2">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button @click="submit" variant="success"> 儲存 </b-button>
                </div>
              </section>
            </b-card>
          </b-overlay>
        </div>
      </div>
    </div>
    <!-- Form End -->
  </div>
</template>

<script>
import { zh } from "vuejs-datepicker/dist/locale";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import bookingApi from "@/apis/booking";
import AppFileUploader from "@/components/AppFileUploader.vue";
import JsonEditor from "@/components/Dashboard/JsonEditor.vue";
import DynamicFormEditor from "@/components/DynamicFormEditor/DynamicFormEditor.vue";
import DynamicFormReadOnly from "@/components/DynamicFormEditor/DynamicFormReadOnly.vue";
import PageAndSectionEditor from "@/components/DynamicFormEditor/PageAndSectionEditor.vue";
import TagsBox from "@/components/TagsBox";
import TrixEditor from "@/components/TrixEditor";
import mergeDefaultAndDataModel from "@/utils/mergeDefaultAndDataModel";
import deepGet from "lodash/get";
import { invoke } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { mapGetters } from "vuex";
import collectionApi from "@/apis/collection";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import NameBlock from "@/pages/Dashboard/Share/NameBlock.vue";
import AppTimePicker from '@/components/AppTimePicker.vue';
import {format, isBefore, set} from "date-fns";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import ColorPicker from "@/components/ColorPicker.vue";

export default {
  components: {
    'color-picker': ColorPicker,
    AppTimePicker,
    AppFileUploader,
    JsonEditor,
    DynamicFormEditor,
    DynamicFormReadOnly,
    PageAndSectionEditor,
    TagsBox,
    NameBlock,
    TrixEditor,
    Datepicker,
    VueTimepicker
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      bookingPreset: {
        name: { required },
        type: { required },
        start_at: {
          date: {
            required,
            beforeEndAt: () => {
              if (!this.bookingPreset.end_at.date || !this.bookingPreset.end_at.time.HH || !this.bookingPreset.end_at.time.mm) {
                return true
              }

              const startDate = set(new Date(this.bookingPreset.start_at.date), {
                hours: this.bookingPreset.start_at.time.HH,
                minutes: this.bookingPreset.start_at.time.mm,
              });

              const endDate = set(new Date(this.bookingPreset.end_at.date), {
                hours: this.bookingPreset.end_at.time.HH,
                minutes: this.bookingPreset.end_at.time.mm,
              });

              return isBefore(startDate, endDate);
            },
          },
          time: {
            HH: { required },
            mm: { required },
          },
        },
        end_at: {
          date: { required },
          time: {
            HH: { required },
            mm: { required },
          },
        },
        config: {
          daily_booking_reminder: !(this.isEditing && this.step === '#booking-setting') ? {} : {
            "array": helpers.withMessage(
              "必須為 Array 格式",
              (value, vm) => invoke(vm, '$refs.dailyBookingReminder.isValidArray') || false,
            ),
          },
          provider_config: {
            status_filter_mapping: !(this.isEditing && this.step === "#mapping")
            ? {}
            : {
              "array": helpers.withMessage(
                "必須為 Array 格式",
                (value, vm) => vm.$refs.statusFilterMapping?.isValidArray() || false,
              ),
            },
            booking_status_mapping: !(this.isEditing && this.step === "#liff-setting")
            ? {}
            : {
                "array": helpers.withMessage(
                  "必須為 Array 格式",
                  (value, vm) => vm.$refs.bookingStatusMapping?.isValidArray() || false,
                ),
            },
          },
          liff_field_setting: {
            list: !(this.isEditing && this.step === '#liff-setting') ? {} : {
              "array": helpers.withMessage(
                "必須為 Array 格式",
                (value, vm) => invoke(vm, '$refs.liffFieldSetting.isValidArray') || false,
              ),
            },
          },
          status_change_events: !(this.isEditing && this.step === '#booking-setting') ? {} : {
            "array": helpers.withMessage(
              "必須為 Array 格式",
              (value, vm) => vm.$refs.statusChangeEvent?.isValidArray() || false,
            ),
          },
          status_detector: !(this.isEditing && this.step === '#liff-setting') ? {} : {
            "array": helpers.withMessage(
              "必須為 Array 格式",
              (value, vm) => vm.$refs.statusDetector?.isValidArray() || false,
            ),
          },
          quotation_content_header: !(this.isEditing && this.step === '#booking-list-quotation-setting') ? {} : {
            "array": helpers.withMessage(
              "必須為 Array 格式",
              (value, vm) => invoke(vm, '$refs.quotationContentHeaderManagement.isValidArray') || false,
            ),
          },
          quotation_content: !(this.isEditing && this.step === '#booking-list-quotation-setting') ? {} : {
            "array": helpers.withMessage(
              "必須為 Array 格式",
              (value, vm) => invoke(vm, '$refs.quotationContentManagement.isValidArray') || false,
            ),
          },
          quotation_content_footer: !(this.isEditing && this.step === '#booking-list-quotation-setting') ? {} : {
            "array": helpers.withMessage(
              "必須為 Array 格式",
              (value, vm) => invoke(vm, '$refs.quotationContentFooterManagement.isValidArray') || false,
            ),
          },
          can_edit_at_liff_mapping: !(this.isEditing && this.step === '#mapping') ? {} : {
            "array": helpers.withMessage(
              "必須為 Array 格式",
              (value, vm) => invoke(vm, '$refs.editLiffMapping.isValidArray') || false,
            ),
          },
          service: {
            title_item_id: !(this.isEditing && this.step === '#wizard-mode' && this.bookingPreset.config.service.collection_id) ? {} : {
              required
            }
          },
          notify: {
            confirmed: {
              status_mapping: !(this.isEditing && this.step === '#booking-setting') ? {} : {
                "array": helpers.withMessage(
                  "必須為 Array 格式",
                  (value, vm) => vm.$refs.confirmedStatusMappingRef?.isValidArray() || false,
                ),
              },
            }
          }
        },
      },
    };
  },
  computed: {
    consts() {
      return consts;
    },
    ...mapGetters("route", ["routeQuery"]),
    limitTimeField() {
      const startAtFieldId = deepGet(this, "bookingPreset.config.booking_mapping.bookings.start_at", null)
      const timeField = deepGet(this, "bookingPreset.config.fields", []).find((field) => field._id == startAtFieldId);
      return timeField
    },
    dateFieldCandidates() {
      return [
        { text: "未設定", value: null },
        ...this.bookingPreset.config.fields.filter((field) => {
           return (field.type == 'datetime') && ['datetime', 'date'].includes(field.format)
        }).map((field) => ({
          text: deepGet(field, "config.title") || deepGet(field, "title"),
          value: deepGet(field, "config._id") || deepGet(field, "_id"),
        })),
      ];
    },
    branchFieldCandidates() {
      return [
        { text: "未設定", value: null },
        ...this.bookingPreset.config.fields.filter((field) => {
           return field.type == 'shop_selection'
        }).map((field) => ({
          text: deepGet(field, "config.title") || deepGet(field, "title"),
          value: deepGet(field, "config._id") || deepGet(field, "_id"),
        })),
      ];
    },
    serviceFieldCandidates() {
      return [
        { text: "未設定", value: null },
        ...this.bookingPreset.config.fields.filter((field) => {
           return ['collection_carousel', 'collection_select'].includes(field.type)
        }).map((field) => ({
          text: deepGet(field, "config.title") || deepGet(field, "title"),
          value: deepGet(field, "config.collection_id") || deepGet(field, "collection_id"),
        })),
      ];
    },
    collectionFieldCandidates() {
      return [
        { text: "未設定", value: null },
        ...this.bookingPreset.config.fields.map((field) => ({
          text: deepGet(field, "config.title") || deepGet(field, "title"),
          value: deepGet(field, "config._id") || deepGet(field, "_id"),
        })),
      ];
    },
    titleOptions() {
      let fields = [
        {
          text: "預約編號",
          value: "booking_number",
        },
      ];
      if (this.bookingPreset.type == "wizard") {
        fields = [
          {
            text: "分店",
            value: "branch_title",
          },
          {
            text: "員工",
            value: "staff_title",
          },
          {
            text: "服務",
            value: "service_title",
          },
          {
            text: "預約編號",
            value: "booking_number",
          },
          ...fields,
        ];
      }
      return [...this.collectionFieldCandidates, ...fields];
    },
  },
  data() {
    return {
      zh,
      isEditing: false,
      showLoading: false,
      validationErrors: null,
      step: "",
      fields: [],
      bookingPreset: {
        name: null,
        type: "normal",
        status: "draft",
        start_at: {
          date: new Date(),
          time: {
            HH: "00",
            mm: "00",
          },
        },
        end_at: {
          date: null,
          time: {
            HH: "23",
            mm: "59",
          },
        },
        config: {
          push_template:{
            header_bg_color : "",
            button_bg_color : ""
          },
          created_push_message: false,
          created_push_message_content: "",
          every_day_message: "",
          daily_booking_reminder: [],
          welcome_page: {
            enable: false,
            banner_image: null,
            form_title: "",
            form_desc: "",
            content: "",
            button_text: "",
          },
          content_page: {
            displayed_in_every_page: false,
            banner_image: "",
            background_image: "",
            form_title: "",
            form_desc: "",
            content: "",
            submit_button_text: "送出",
          },
          finished_page: {
            enabled_next_booking_button: true,
            enabled_go_booking_list_button: true,
            content: "",
            banner_image: null,
          },
          fields: [],
          description: null,
          booking_mapping: {
            bookings: {
              title: null,
              status: null,
              start_at: null,
              end_at: null,
              end_slot_minutes: 0,
              start_at_by_branch: null
            },
            default_status_value: null,
          },
          provider_config: {
            status_filter_mapping: [],
            booking_status_mapping: [],
          },
          liff_field_setting: {
            list: [],
          },
          event_setting: {
            booking_tags: [],
          },
          enabled_selected_staff: false,
          enabled_selected_staff_shift: false,
          enabled_staff_service_relation: false,
          enabled_selected_branch: false,
          branch: {
            field_id: null
          },
          service: {
            collection_id: null,
            image_item_id: null,
            title_item_id: null,
            description_item_id: null,
            price_item_id: null,
            duration_item_id: null,
            can_multiple_select: false,
          },
          order: {
            staff_page: 2,
            branch_page: 1,
            service_page: 3,
          },
          pages: [],
          sections: [],
          status_detector: [],
          status_change_events: [],
          quotation_header: null,
          quotation_content_header: [],
          quotation_content: [],
          quotation_content_footer: [],
          quotation_footer: null,
          can_edit_at_liff_mapping: [],
          occupy_config: {
            collection_field_id: null,
            collection_id: null,
            enable: false,
            is_check_branch: false,
            is_check_collection: false,
            is_check_service: false,
            is_check_staff: false,
            occupy_status: [],
            time_options: [],
            limit_type: null,
            slot_people: 0
          },
          notify: {
            edit: {
              enabled: false,
            },
            cancel: {
              enabled: false,
              status_mapping: [],
            },
            confirmed: {
              enabled: false,
              status_mapping: [],
            },
            completed: {
              enabled: false,
              status_mapping: [],
            }
          },
          modified_push_message_content: "",
          canceled_push_message_content: "",
          confirmed_push_message_content: "",
          completed_push_message_content: "",
          created_push_header_image_url: "",
          canceled_push_header_image_url: "",
          modified_push_header_image_url: "",
          completed_push_header_image_url: "",
          confirmed_push_header_image_url: "",
        },
      },
      bookingPresetTypes: [
        { text: "一般模式", value: "normal" },
        { text: "精靈模式", value: "wizard" },
      ],
      collectionOptions: [],
      orderOptions: [
        {
          text: "第一",
          value: 1,
        },
        {
          text: "第二",
          value: 2,
        },
        {
          text: "第三",
          value: 3,
        },
      ],
      permissionChecker: new PermissionChecker(),
      validSubject: false
    };
  },
  mounted() {
    if (this.$route.name === "BookingPresetEdit") {
      this.isEditing = true;
      this.bookingPreset.id = this.$route.params.id;
      this.fetchBookingPreset();
      this.navigateToStepFromCurrentRoute();
    } else {
      this.isEditing = false;
      this.$set(
        this,
        "bookingPreset",
        this.mergeDefaultAndDataModel(this.bookingPreset, null)
      );
    }
    this.fetchCollections();
  },
  methods: {
    deepGet,
    mergeDefaultAndDataModel,
    changeService() {
      this.bookingPreset.config.service.image_item_id = null;
      this.bookingPreset.config.service.description_item_id = null;
      this.bookingPreset.config.service.title_item_id = null;
      this.bookingPreset.config.service.price_item_id = null;
      this.bookingPreset.config.service.duration_item_id = null;
    },
    collectionItemOptions(collectionId) {
      return [
        { text: "未設定", value: null },
        ...this.collectionOptions
          .find(
            (collection) =>
              collection.value == collectionId
          )
          ?.config?.fields.map((field) => {
            return {
              value: field._id ?? field.config._id,
              text: field.title ?? field.config.title,
            };
          }) ?? []
      ];
    },
    async fetchCollections() {
      try {
        let response = await collectionApi.getCollections({
          with_items: 1,
          is_enabled: 1,
        });
        this.collectionOptions = response.data.data.map((collection) => {
          return {
            value: collection.id,
            text: collection.name + " 共 " + collection.items_count + " 筆資料",
            items: collection.items,
            config: collection.config,
          };
        });
      } catch (error) {
        console.error(error);
      }
    },
    presentValidationErrors(validationKey) {
      return deepGet(this.v$, validationKey + ".$errors", [])
        .map((error) => error.$message)
        .join(", ");
    },
    cancel() {
      this.$router.push({ name: "BookingPresetList", query: this.routeQuery });
    },
    goToStep(step) {
      if (!this.bookingPreset.id) {
        return;
      }

      if (this.step == step) {
        return;
      }

      this.step = step;
      if (location.hash !== this.step) {
        // NOTE: 這裡不使用 $router.replace，以避免 page rerender 導致 mounted 或其他 lifecycle hook 的內的 API 重複執行
        const newLocation = new URL(location.href);
        newLocation.hash = this.step;
        window.history.replaceState("", "", newLocation.href);
      }
    },
    navigateToStepFromCurrentRoute() {
      const anchor = this.$route.hash;
      if (anchor) {
        this.goToStep(anchor);
      }
    },
    async submit() {
      console.log("step");
      console.log(this.step);
      console.log(this.bookingPreset.config);
      const result = await this.v$.$validate();
      if (!result) {
        if (this.step !== '') {
          this.$swal({
            type: 'error',
            title: '欄位填寫有誤',
            html: this.v$.$errors
              .map(error => `${error.$propertyPath}: ${error.$message}`)
              .join('<br/>'),
            confirmButtonText: '確認',
          })
        }
        return false;
      }

      if (this.step === '#booking-setting') {
        // 表頭背景色
        const headerBgColor  = this.bookingPreset.config.push_template.header_bg_color;
        const colorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/; // 檢查 #RRGGBB 或 #RGB 格式
        if (headerBgColor !== null) {
          if (!colorRegex.test(headerBgColor)) {
            this.$swal({
              type: 'error',
              title: '表頭背景色格式不正確',
            });
            return false;
          }
        }
        // 按鈕背景色
        const buttonBgColor  = this.bookingPreset.config.push_template.button_bg_color;
        if (buttonBgColor !== null) {
          if (!colorRegex.test(buttonBgColor)) {
            this.$swal({
              type: 'error',
              title: '按鈕背景色格式不正確',
            });
            return false;
          }
        }
      }

      try {
        this.showLoading = true;

        const payload = {
          ...this.bookingPreset,
          start_at: this.formatDatetime(this.bookingPreset.start_at),
          end_at: this.formatDatetime(this.bookingPreset.end_at),
        };

        // 編輯表單
        if (this.isEditing) {
          await bookingApi.updateBookingPreset(
            this.bookingPreset.id,
            payload
          );
          await this.fetchBookingPreset();
          this.$swal("儲存成功", "", "success");
        }
        // 新增表單
        else {
          // const { pages, sections } = this.createDefaultPageAndSections();
          this.$set(this.bookingPreset.config, "pages", []);
          this.$set(this.bookingPreset.config, "sections", []);
          let response = await bookingApi.createBookingPreset(payload);
          this.bookingPreset = response.data.data;
          this.$swal("新增成功", "", "success");
          this.isEditing = true;
          this.$router.replace({
            name: "BookingPresetEdit",
            params: { id: response.data.data.id },
          });
        }

        return true;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存失敗", "error");
        return false;
      } finally {
        this.showLoading = false;
      }
    },
    async fetchBookingPreset() {
      if (!this.bookingPreset.id) {
        this.$swal("錯誤", "無法取得讀取 ID", "error");
        return;
      }

      try {
        this.showLoading = true;
        let response = await bookingApi.getBookingPreset(this.bookingPreset.id);
        let bookingPreset = response.data.data;

        if (bookingPreset.start_at) {
          bookingPreset.start_at = this.isoStringToDatetime(bookingPreset.start_at);
        }
        if (bookingPreset.end_at) {
          bookingPreset.end_at = this.isoStringToDatetime(bookingPreset.end_at);
        }

        this.$set(
          this,
          "bookingPreset",
          this.mergeDefaultAndDataModel(this.bookingPreset, bookingPreset)
        );
        this.fields = this.bookingPreset.config.fields;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料錯誤", "error");
        this.cancel();
      } finally {
        this.showLoading = false;
      }
    },
    async saveFields(fields) {
      this.validSubject = true
      let emptyTitleSubjects = [];
      fields.forEach((subject)=>{
        if(!subject.title){
          emptyTitleSubjects.push(subject.order);
          return;
        }
      })
      if(emptyTitleSubjects.length > 0){
        return;
      }

      // cuz 更新會直接用put preset 時間格式要format 不然會存不進去
      this.bookingPreset.start_at = this.formatDatetime(this.bookingPreset.start_at);
      this.bookingPreset.end_at = this.formatDatetime(this.bookingPreset.end_at);

      try {
        this.showLoading = true;

        this.bookingPreset.config.fields = fields;

        await bookingApi.updateBookingPreset(
          this.bookingPreset.id,
          this.bookingPreset
        );
        await this.fetchBookingPreset();
        this.$swal("儲存成功", "", "success");
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存資料錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    createDefaultPageAndSections() {
      const pages = Array.from(Array(3)).map((empty, index) => {
        return {
          _id: uuidv4(),
          order: index + 1,
          title: `第${index + 1}頁`,
        };
      });

      const sections = pages.reduce((carry, page) => {
        return carry.concat(
          Array.from(Array(2)).map((empty, index) => {
            return {
              _id: uuidv4(),
              order: index + 1,
              title: `第${page.order}-${index + 1}區`,
              page_id: page._id,
              visible_when_any: [],
            };
          })
        );
      }, []);

      return { pages, sections };
    },
    checkPermission(permissions) {
      return this.permissionChecker.check(permissions);
    },
    generateTimeOptions() {
        let options = [];
        const timeField = this.limitTimeField
        if (!timeField) {
          return;
        }
        const slotMinutes = timeField.slot_minutes;
        const startTime = timeField.start_time;
        const endTime = timeField.end_time;
        if (slotMinutes <= 0 || this.bookingPreset?.config?.occupy_config?.slot_people < 0) {
          return;
        }
        let [checkHours, checkMinutes] = startTime.split(':').map(Number);
        if (checkHours < 0 || checkMinutes > 60) {
          return;
        }

        [checkHours, checkMinutes] = endTime.split(':').map(Number);
        if (checkHours < 0 || checkMinutes > 60) {
          return;
        }

        let currentTime = startTime;
        // 計算實際的最後服務時間
        let actualEndTime = endTime;


        /* eslint-disable no-constant-condition */
        while (true) {
          options.push({
            title: currentTime,
            value: this.bookingPreset.config.occupy_config.slot_people
          });

          // 將 currentTime 轉為 Date 物件，便於時間加法
          const [hours, minutes] = currentTime.split(':').map(Number);
          let date = new Date(0, 0, 0, hours, minutes + Number(slotMinutes));
          // 將新的時間轉回字符串格式 "HH:MM"
          currentTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

          // 停止條件：若不包含 endTime，且當前時間達到 endTime 或超過
          if (currentTime > actualEndTime) {
            break;
          }
        }
        /* eslint-enable no-constant-condition */
        this.bookingPreset.config.occupy_config.time_options = options;
      },
    handlePageAndSectionUpdate() {
      this.submit()
    },
    formatDatetime(datetime) {
      return format(
        set(new Date(datetime.date), {
          hours: Number(datetime.time.HH),
          minutes: Number(datetime.time.mm),
          seconds: 0,
        }),
        "yyyy-MM-dd HH:mm:ss"
      );
    },
    isoStringToDatetime(isoString) {
      const date = new Date(isoString);

      return {
        date: date,
        time: {
          HH: String(date.getHours()).padStart(2, "0"),
          mm: String(date.getMinutes()).padStart(2, "0"),
        },
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.menu-items > li {
  cursor: pointer;

  &.disabled {
    background-color: #ddd;
    opacity: 0.5;
    pointer-events: none;
  }

  &.has-checkbox {
    &:after {
      content: "\f134";
      font-family: "Material Design Icons";
      font-size: 16px;
    }
  }

  &.has-checkbox.has-checkbox--checked {
    &:after {
      content: "\f133";
      color: green;
    }
  }
}

.list-none {
  list-style-type: none;
}
</style>
