<template>
  <div class="visible-condition-editor">
    <b-input-group class="visible-condition-editor__condition" v-for="(condition, conditionIndex) in conditionList" :key="conditionIndex">
      <b-input-group-prepend>
        <span class="mdi mdi-lightbulb-on-outline option-drag-handler"></span>
      </b-input-group-prepend>
      <div class="visible-condition-editor__advance-option-content-wrapper">
        <span>{{ conditionIndex > 0 ? '或當' : '當' }}</span>
        <b-form-select
          v-model="condition.field_id"
          :options="visibleConditionFieldCandidates"
          @input="handleVisibleConditionFieldChanges(conditionIndex)"
          style="max-width: 200px"
        ></b-form-select>
        <span>為</span>
        <b-form-select
          v-model="condition.value"
          :options="visibleConditionValueCandidates(conditionIndex)"
          @input="handleVisibleConditionOptionChanges"
          style="max-width: 200px"
        ></b-form-select>
        <!-- 增加減少 -->
        <b-button class="ml-2 p-1 d-inline-flex align-items-center text-nowrap" variant="inverse-success" @click="createCondition(conditionIndex + 1)">
          <span class="mdi mdi-18px mdi-playlist-plus"></span>
          增加
        </b-button>
        <b-button class="ml-2 p-1 d-inline-flex align-items-center text-nowrap" variant="inverse-danger" @click="removeCondition(conditionIndex)">
          <span class="mdi mdi-18px mdi-playlist-remove"></span>
          移除
        </b-button>
      </div>
    </b-input-group>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    fieldOptionsForVisibleConditions: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      conditionList: [],
    }
  },
  computed: {
    visibleConditionFieldCandidates() {
      return [
        { text: '特定題目', value: null },
        ...this.fieldOptionsForVisibleConditions.filter(field => Object.keys(field).includes('options')).map(field => ({ text: field.title, value: field._id })),
      ]
    },
  },
  mounted() {
    if (this.value.length > 0) {
      this.conditionList = this.value.map(condition => ({
        field_id: condition.field_id,
        value: condition.value,
      }))
    }
  },
  methods: {
    visibleConditionValueCandidates(conditionIndex) {
      const fieldId = _.get(this.conditionList, `[${conditionIndex}].field_id`)
      const matchedField = this.fieldOptionsForVisibleConditions.find(field => field._id === fieldId)
      return [
        { text: '特定選項', value: null },
        ..._.get(matchedField, 'options', []),
      ]
    },
    createCondition(index = undefined) {
      const indexToInsert = index === undefined ? this.conditionList.length : index
      this.conditionList.splice(indexToInsert, 0, {
        field_id: null,
        value: null,
      });
      this.$emit('input', _.cloneDeep(this.conditionList))
    },
    removeCondition(index) {
      const isDeletingTheLastCondition = this.conditionList.length === 1
      this.conditionList.splice(index, 1);
      this.$emit('input', _.cloneDeep(this.conditionList))
      if (isDeletingTheLastCondition) {
        this.$emit('cleaned')
      }
    },
    handleVisibleConditionFieldChanges(conditionIndex) {
      this.conditionList[conditionIndex].value = null
      this.$emit('input', _.cloneDeep(this.conditionList))
    },
    handleVisibleConditionOptionChanges() {
      this.$emit('input', _.cloneDeep(this.conditionList))
    },
  }
}
</script>

<style lang="scss" scoped>
.visible-condition-editor {
  display: inline-grid;
  grid-template-columns: auto;

  &__condition {
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: #eee;
    width: unset;

    .input-group-prepend {
      background-color: transparent;
    }

    span.mdi {
      margin-left: 12px;
    }

    input.form-control {
      border: none;
      background-color: transparent;
    }

    select.custom-select {
      margin: 0 12px;
      font-size: 0.75rem;
      font-weight: 300;
    }
  }

  &__advance-option-content-wrapper {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    padding: 8px 12px;

    span {
      font-size: 0.75rem;
      font-weight: 300;
      white-space: nowrap;
    }
  }
}
</style>
