<template>
  <div class="subject" :class="{ 'subject--is-expanded': detailIsExpanded }">
    <div class="subject__index d-flex align-items-center">
      <span class="subject__sequence">第 {{ index + 1 }} 頁</span>
    </div>

    <div class="subject__title">
      <b-form-input :class="{ 'is-invalid': validate && titleValidState === false }" ref="titleInputRef" v-model="vModelForTitle" @input="value => $emit('update:title', value)" :disabled="isReadOnly" :state="validate ? titleValidState : null" :placeholder="validate && titleValidState === false ? validationMessage : ''"></b-form-input>
    </div>

    <div class="subject__sorting d-flex align-items-center">
      <i class="mdi mdi-24px mdi-drag-horizontal cursor-grab subject-layout-drag-handler"></i>
      <b-button class="ml-2 subject__button" :class="{ 'subject__button--disabled': moveUpIsDisabled || isReadOnly }" variant="secondary" @click="$emit('top')" :disabled="isReadOnly">
        <span class="mdi mdi-arrow-collapse-up"></span>
        移至最頂
      </b-button>
      <b-button class="ml-2 subject__button" :class="{ 'subject__button--disabled': moveUpIsDisabled || isReadOnly }" variant="secondary" @click="$emit('up')" :disabled="isReadOnly">
        <span class="mdi mdi-arrow-up"></span>
        上移
      </b-button>
      <b-button class="ml-2 subject__button" :class="{ 'subject__button--disabled': moveDownIsDisabled || isReadOnly }" variant="secondary" @click="$emit('down')" :disabled="isReadOnly">
        <span class="mdi mdi-arrow-down"></span>
        下移
      </b-button>
      <b-button class="ml-2 subject__button" :class="{ 'subject__button--disabled': moveDownIsDisabled || isReadOnly }" variant="secondary" @click="$emit('bottom')" :disabled="isReadOnly">
        <span class="mdi mdi-arrow-collapse-down"></span>
        移至最底
      </b-button>
    </div>

    <div class="subject__deletion d-flex align-items-center">
      <b-button class="subject__button" variant="danger" @click="confirmForDeletion" :disabled="isReadOnly">
        <span class="mdi mdi-delete"></span>
        移除
      </b-button>
    </div>

    <div class="subject__options" :class="{ 'd-none': !detailIsExpanded }">
      <VisibleConditionEditor
        v-if="visibleConditionIsVisible"
        v-model="vModelForVisibleConditions"
        :fieldOptionsForVisibleConditions="fieldOptionsForVisibleConditions"
        @input="handleVisibleConditionChanges"
        @cleaned="visibleConditionIsVisible = false"
      />
      <div class="subject__toggle-wrapper">
        <b-button class="subject__button" :variant="visibleConditionIsVisible ? 'primary' : 'secondary'" @click="toggleVisibleCondition" :disabled="isReadOnly" >
          <span class="mdi mdi-lightbulb-on-outline"></span>
          觸發顯示
        </b-button>
      </div>
    </div>

    <div class="subject__slot" :class="{ 'd-none': !detailIsExpanded }">
      <slot></slot>
    </div>

    <div class="subject__expand">
      <b-button class="d-flex align-items-center justify-content-center w-100 border-0 bg-white" @click="toggleDetails">
        <i class="mdi mdi-24px mdi-chevron-down"></i>
      </b-button>
    </div>
  </div>
</template>

<script>
import VisibleConditionEditor from "@/components/DynamicFormEditor/VisibleConditionEditor.vue";
import _ from "lodash";

export default {
  components: {
    VisibleConditionEditor,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    moveUpIsDisabled: {
      type: Boolean,
      default: false,
    },
    moveDownIsDisabled: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    visibleConditions: {
      type: Array,
      default: () => ([]),
    },
    fieldOptionsForVisibleConditions: {
      type: Array,
      default: () => ([]),
    },
    validate: {
      type: Boolean,
      default: false,
    },
    validationMessage: {
      type: String,
      default: '此欄位為必填',
    },
  },
  data() {
    return {
      detailIsExpanded: false,
      vModelForTitle: '',
      visibleConditionIsVisible: false,
      vModelForVisibleConditions: [],
    }
  },
  computed: {
    isReadOnly() {
      return this.readonly
    },
    locationIsEnabled() {
      return ['BookingPresetEdit'].includes(this.$route.name);
    },
    titleValidState() {
      const passStyle = null;
      const errorStyle = false;
      return this.vModelForTitle.trim() ?  passStyle : errorStyle;
    }
  },
  mounted() {
    this.vModelForTitle = this.title
    this.vModelForVisibleConditions = this.visibleConditions

    this.detailIsExpanded = this.expanded

    if (_.get(this.visibleConditions, 'length', 0) > 0) {
      this.visibleConditionIsVisible = true
    }
  },
  methods: {
    async confirmForDeletion() {
      const consented = await this.$swal({
        title: this.title ? `確認刪除【${this.title}】嗎？` : '確認刪除嗎？',
        // text: ,
        type: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonColor: "#3085d6",
        cancelButtonText: "不刪除",
        confirmButtonColor: "#d33",
        confirmButtonText: "確定刪除",
        reverseButtons: false,
      })

      if (!consented.value) {
        return
      }

      this.$emit('remove')
    },
    toggleVisibleCondition() {
      this.visibleConditionIsVisible = !this.visibleConditionIsVisible

      if (this.visibleConditionIsVisible) {
        this.vModelForVisibleConditions = [{
          field_id: null,
          value: null,
        }]
        this.$emit('update:visibleConditions', this.vModelForVisibleConditions)
      } else {
        this.vModelForVisibleConditions = []
        this.$emit('update:visibleConditions', [])
      }
    },
    toggleDetails() {
      this.detailIsExpanded = !this.detailIsExpanded
      this.$emit('update:expanded', this.detailIsExpanded)
    },
    handleVisibleConditionChanges(val) {
      this.$emit('update:visibleConditions', val)
    },
  }
}
</script>

<style lang="scss" scoped>
.subject {
  display: grid;
  grid-template-columns: 114px auto 350px 60px;
  grid-gap: 0 16px;
  grid-template-areas:
    "index  title   sorting deletion"
    ".      options options options"
    "slot   slot    slot    slot"
    "expand expand  expand  expand";

  $sections: index, title, sorting, deletion, options, slot, expand;
  @each $sectionName in $sections {
    &__#{$sectionName} {
      grid-area: $sectionName;
    }
  }

  &--is-expanded {
    .subject__expand i {
      transform: rotate(180deg);
    }
  }
  .is-invalid::placeholder {
    color: #dc3545;
  }
  .subject__sequence {
    border-radius: 4px;
    padding: 4px 12px;
    background-color: #eeeeee;
    font-size: .875rem;
  }
  &__options {
    margin-top: 16px;
  }
  &__slot {
    margin-top: 16px;
  }

  &__button {
    padding: 4px 6px;

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed !important;
    }
  }

  &__toggle-wrapper {
    margin: -2px -4px;

    > * {
      margin: 2px 4px;
    }
  }
}
</style>
